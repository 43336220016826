<template>
  <div>
      <app-layout>
          <template v-slot:header>
              <Header :title="$t('my_room_reservation')"
                      :isFilter="false"
                      :isColumns="false"
              />
          </template>
          <template v-slot:header-mobile>
              <HeaderMobile 
                  :title="$t('my_room_reservation')"
                  :isFilter="false"
                  :isColumns="false"
              />
          </template>
          <datatable :isLoading.sync="datatable.isLoading"
                     :columns="datatable.columns"
                     :rows="datatable.rows"
                     :total="datatable.total"
                     :queryParams="datatable.queryParams"
                     @on-page-change="onPageChange"
                     @on-sort-change="onSortChange"
                     @on-per-page-change="onPerPageChange"
                     v-show="datatable.showTable"
          />
      </app-layout>
  </div>
</template>
<script>
  // Component
  import Datatable from '@/components/datatable/Datatable'

  // Services
  import HousingReservationsListService from '@/services/HousingReservationsListService'

  // Pages
  import qs from 'qs'

  export default {
      props: {
          rsRefresh: {
              type: Boolean,
              default: false
          }
      },
      components: {
          Datatable
      },
      metaInfo() {
          return {
              title: this.$t('my_room_reservation')
          }
      },
      data() {
          return {
              datatable: {
                  isLoading: false,
                  columns: [
                      {
                          label: this.$t('check_in_date'),
                          field: 'check_in_date',
                          sortable: true
                      },
                      {
                          label: this.$t('check_out_date'),
                          field: 'check_out_date',
                          sortable: true
                      },
                      {
                          label: this.$t('days'),
                          field: 'days',
                          sortable: true
                      },
                      {
                          label: this.$t('fee'),
                          field: 'fee',
                          sortable: true
                      },
                      {
                          label: this.$t('total_amount'),
                          field: 'total_amount',
                          sortable: true
                      },
                  ],
                  rows: [],
                  total: 0,
                  showTable: true,
                  queryParams: {
                      filter: {
                      },
                      sort: 'id',
                      page: 1,
                      limit: 20
                  }
              },
              formLoading: false,
              formId: null,
              formProcess: null,
          }
      },
      created() {
        this.getRows();
    },
      methods: {

          onPageChange(params) {
              this.datatable.queryParams.page = params.currentPage;
              this.getRows();
          },
          onPerPageChange(params) {
              this.datatable.queryParams.limit = params.currentPerPage;
              this.datatable.queryParams.page = 1;
              this.getRows();
          },
          onSortChange(params) {
              const sortType = params[0].type == 'desc' ? '-' : '';
              this.datatable.queryParams.sort = sortType + params[0].field;
              this.getRows();
          },
          async getRows() {
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    headers: {
                        Authorization: `Bearer ${this.$route.params.token}`
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                };

                  return HousingReservationsListService.getAll(config)
                      .then((response) => {
                          this.datatable.rows = response.data.data;
                          this.datatable.total = response.data.pagination.total;
                      })
                      .catch((e) => {
                          this.showErrors(e);
                      })
                      .finally(() => {
                          this.datatable.isLoading = false;
                      }
                  );
          },
      }
  }
</script>